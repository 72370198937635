// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
global.toastr = require("toastr")

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
window.Rails = Rails;
require('jquery')
window.jQuery = $;
window.$ = $;
require("bootstrap")
require("@nathanvda/cocoon");
import 'stylesheets/application'
import "select2";
import "select2/dist/css/select2.css";
require("parsleyjs")
require('jquery-mask-plugin')
import "@fortawesome/fontawesome-free/css/all"
require("packs/plugins/jquery-ui.js")
window.moment= require('moment')
window.intlTelInput = require('packs/plugins/intlTelInput.min')
require('packs/plugins/bootstrap-select.min.js')
require('packs/plugins/timepicker.min.js')
require('packs/plugins/daterangepicker.min.js')
require('packs/plugins/jquery.datepair.min')
// require("packs/plugins/datatables.min")
// require('packs/plugins/select2.min.js')
require('packs/plugins/jquery.blockUI.js')
require("packs/plugins/bootstrap-datetimepicker.min")
require('packs/plugins/bootstrap-datepicker.min.js')
require("packs/plugins/datatable.yadcf")
require('packs/custom/custom_mobile_number')
require("packs/custom/crm.js")
require("packs/custom/simple_datatable.js")
require("packs/custom/ajax_datatable.js")
require('packs/custom/custom.js')
require("packs/custom/custom_ckeditor.js")
require("packs/plugins/custom_multi_select.js")
require("packs/custom/project.js")
require('toastr');
require("packs/cart.js");
import Sortable from "sortablejs";
require("packs/owl.carousel.min.js")



import "select2";
import "select2/dist/css/select2.css";

const images = require.context('../images', true)
require("datatables.net")
require('datatables.net-bs4')
require("datatables.net-bs4/css/dataTables.bootstrap4.min.css")

const dataTables = [];

document.addEventListener("turbolinks:load", () => {
  $('form').parsley();
  if (dataTables.length === 0 && $('.data-table').length !== 0) {
    $('.data-table').each((_, element) => {
      dataTables.push($(element).DataTable({
        pageLength: 25
      }));
    });
  }

  const sortingElement = document.getElementById("sortable-elem");

  // get calls only if div with id sortable-elem and onEnd function exists
  if (sortingElement && (typeof updateSortableOnEnd === "function")) {
    Sortable.create(sortingElement, {
      handle: ".sorting-handler",
      onEnd: updateSortableOnEnd,
    });
  }
});

document.addEventListener("turbolinks:before-cache", () => {
  while (dataTables.length !== 0) {
    dataTables.pop().destroy();
  }
});

Crm.events.onLoaded(function() {
  // $('.phone-number').mask('(999) 999-9999')
  $('.timepicker').timepicker({timeFormat: 'h:i A'});
  $('.bootstrapdatepicker').datepicker({format: 'mm/dd/yyyy', todayHighlight: true, autoclose: true});
  var min_date_datepicker = new Date();
  min_date_datepicker.setHours(0,0,0,0);
  $('.block_past_datepicker').datetimepicker({
    minDate: min_date_datepicker,
    format: "MM/DD/YYYY",
    icons: {
      date: "fa fa-calendar",
      up: "fa fa-arrow-up",
      down: "fa fa-arrow-down",
      previous: "fa fa-chevron-left",
      next: "fa fa-chevron-right",
      today: "fa fa-clock-o",
      clear: "fa fa-trash-o"
    }
  })
  $('.datepicker').datetimepicker({
    format: "MM/DD/YYYY",
    icons: {
      date: "fa fa-calendar",
      up: "fa fa-arrow-up",
      down: "fa fa-arrow-down",
      previous: "fa fa-chevron-left",
      next: "fa fa-chevron-right",
      today: "fa fa-clock-o",
      clear: "fa fa-trash-o"
    }
  })
  $('.daterangepicker1').daterangepicker({
    drops: 'auto',
    autoUpdateInput: true,
    timePicker: false,
    locale: {
      format: 'MM/DD/YYYY'
    }
  });
  // $('.datetimepicker').on('change', function(){
  //   newDate = moment($(this).val(), "MM/DD/YYYY hh:mm A")
  //   if (newDate.isValid()) {
  //     $(this).val(newDate.format("MM/DD/YYYY hh:mm A"))
  //   } else {
  //     $(this).val(this.getAttribute("value"))
  //   }
  // })
  // TODO: added because somehow after adding `autoUpdateInput` the apply button stops working
  // $('.datepicker').on('apply.daterangepicker', function(ev, picker) {
  //   $(this).val(picker.startDate.format(picker.locale.format));
  //   $(this).trigger('change');
  // });
  // $('.datetimepicker').on('apply.daterangepicker', function(ev, picker) {
  //   debugger
  //   $(this).val(picker.startDate.format(picker.locale.format));
  //   $(this).trigger('change');
  // });
  // $('[data-datetimepair-container]').datepair({
  //   dateClass: 'datepicker',
  //   timeClass: 'timepicker',
  //   startClass: 'timestart',
  //   endClass: 'timeend'
  // });
  // $(".multi_select").select2({theme: 'bootstrap', closeOnSelect : true});
  $(".select_picker").selectpicker();
  $('form').submit(function(){
    $(this).find('.datepicker').each(function(){
      if($(this).val() != ""){
        let newDate = moment($(this).val(), 'MM/DD/YYYY').format('DD/MM/YYYY')
        $(this).val(newDate)
      }
    })
    $(this).find('.block_past_datepicker').each(function(){
      if($(this).val() != ""){
        let newDate = moment($(this).val(), 'MM/DD/YYYY').format('DD/MM/YYYY')
        $(this).val(newDate)
      }
    })
  })
})

$( document ).ajaxComplete(function() {
  // $.unblockUI();
});

import { Calendar } from '@fullcalendar/core'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import moment from 'moment'

document.addEventListener('turbolinks:load', function() {
  let calendarEl = document.getElementById('calendar');

  // Initialize Select2 on the providerSelect element
  $('#providerSelect').select2({
    placeholder: "Select providers",
    allowClear: true
  });

  if (calendarEl) {
    window.calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      initialView: 'timeGridDay',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      events: function(fetchInfo, successCallback, failureCallback) {
        // Get the selected provider IDs as an array
        let selectedProviderIds = $('#providerSelect').val();

        // Fetch events with the provider IDs as parameters
        $.ajax({
          url: '/schedule_appointments.json',
          method: 'GET',
          data: {
            provider_ids: selectedProviderIds // Pass provider_ids as parameter
          },
          success: function(data) {
            successCallback(data);
          },
          error: function() {
            failureCallback();
            console.log('There was an error fetching the events.');
          }
        });
      },
      slotDuration: '00:15:00',
      dateClick: function(info) {
        $('#appointmentModal').modal('show');
        clearAppointmentForm();
        const formattedDate = convertDate(info.date)
        $('#appointment_start_time').val(formattedDate);
        $('#additional_provider_ids').select2({
          multiple: true,
          placeholder: 'Select Additional Provider',
          allowClear: true,
          dropdownParent: $("#appointmentModal")
        });
      },
      eventClick: function(info) {
        // Prevent the browser from following the link in the event
        info.jsEvent.preventDefault();

        // Populate the modal with the event's details
        $('#eventTitle').text(info.event.title);
        $('#eventStartTime').text(moment(info.event.start).format('MMMM Do YYYY, h:mm:ss a'));
        $('#eventEndTime').text(moment(info.event.end).format('MMMM Do YYYY, h:mm:ss a'));
        $('#eventDescription').text(info.event.extendedProps.description || '-');
        $('#hidden_appointment_id').text(info.event.id);
        $('#eventMethod').text(info.event.extendedProps.method);
        $('#eventProvider').text(info.event.extendedProps.main_provider);
        $('#eventAddProvider').text(info.event.extendedProps.additional_provider);
        var tooltipContent = info.event.extendedProps.patient_data.replace(/\n/g, '<br>');
        $('#patientInfoIcon').attr('title', tooltipContent).tooltip('update');

        var status = info.event.extendedProps.status;
        var predefinedStatuses = ['scheduled', 'seen', 'cancelled', 'no show', 'rescheduled'];
        if (predefinedStatuses.includes(status)) {
          $('.appointment_status').val(status); 
          $('#other_status').hide(); 
          $('#other_status').val(''); 
        } else {
          $('.appointment_status').val('other'); 
          $('#other_status').val(status); 
          $('#other_status').show(); 
        }
        
        $('[data-toggle="tooltip"]').tooltip({
          html: true // Allow HTML content in tooltip
        });
        $('#eventDetailsModal').modal('show');
      }
    });

    window.calendar.render();

    // Handling the form submission for creating a new appointment
    $('#appointmentForm').on('ajax:success', function(event) {
      let [data, status, xhr] = event.detail;

      // Add the new event to the calendar
      window.calendar.addEvent({
        id: data.id,
        title: data.title,
        start: data.start_time,
        end: data.end_time,
        extendedProps: {
          provider_id: data.provider_id // Assuming `provider_id` is part of the JSON response
        }
      });

      // Hide the modal and reset the form
      $('#appointmentModal').modal('hide');
      $('#appointmentForm')[0].reset();
    }).on('ajax:error', function(event) {
      let [data, status, xhr] = event.detail;
      // Display an error message (you can customize this part)
      alert('There was an error creating the appointment: ' + data.errors.join(", "));
    });

    // Add event listener for provider filter using Select2
    $('#providerSelect').on('change', function() {
      // Refetch events from the server based on selected providers
      window.calendar.refetchEvents();
    });
  }
});

function convertDate(dateString) {

  // Convert to Date object
  const date = new Date(dateString);

  // Extract the year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  // Extract the hours and minutes
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  // Format the date string in 'YYYY-MM-DDTHH:MM' format
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

  return formattedDate
}

function clearAppointmentForm() {
  $('#appointment_start_time').val("");
  $('#schedule_appointment_patient_id').val("");
  $('#provider_id').val("");
  $('#schedule_appointment_appointment_id').val("");
  $('#schedule_appointment_appointment_method').val("");
  $("#reserved-errors").html("");
  $(".form-control").removeClass("parsley-error")
  $(".form-control").removeClass("parsley-success")
}


Rails.start()
Turbolinks.start()
ActiveStorage.start()
